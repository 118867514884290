import { createWorkbook } from './create-workbook'
import { defineFileName } from './define-file-name'
import { makeBuffer } from './make-buffer'
import fileDownload from 'js-file-download'
import { addWorksheets } from './add-worksheets'

export const excel = async (): Promise<void> => {

  // prepare workbook
  let workbook = createWorkbook()

  // filename
  const fileName = defineFileName()

  // add worksheets
  workbook = addWorksheets(workbook)

  // make buffer
  const file_buffer = await makeBuffer(workbook)

  fileDownload(file_buffer, fileName)

}