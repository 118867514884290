import exceljs from 'exceljs/dist/es5/exceljs.browser'
import { addOrdersSheet } from './add-orders-worksheet'

export const addWorksheets = (workbook: exceljs.Workbook): exceljs.Workbook => {

  // if orders, add orders worksheet
  workbook = addOrdersSheet(workbook)

  return workbook

}