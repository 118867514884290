import React from 'react'
import { view as State } from '@risingstack/react-easy-state'
import { expo, report } from '../stores'
import { Button } from '@material-ui/core'

const ExcelButton = () => {

  if (report.data.type != 'organizations') return null

  return report.thereOrders() ? (
    <Button color="inherit" onClick={expo.excel}>
      Excel
    </Button>
  ) : null
}

export default State(ExcelButton)
