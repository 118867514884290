import { store } from '@risingstack/react-easy-state'
import fetch from './fetch'
import { Report } from '../../ts/interfaces'
import thereOrders from './there-orders'
import { getAllOrders } from './get-all-orders'
import { openAgreeDialog } from './open-agree-dialog'
import { closeAgreeDialog } from './close-agree-dialog'
import { setAgreed } from './set-agreed'

const report: Report = store({
  agree_dialog_opened: false,
  fetch,
  thereOrders,
  getAllOrders,
  openAgreeDialog,
  closeAgreeDialog,
  setAgreed
})

export default report