import exceljs from 'exceljs/dist/es5/exceljs.browser'
import { orders } from '../../..'

export const addOrderRow = (sheet: exceljs.Worksheet, order: any): exceljs.Worksheet => {

  const price = orders.getPrice(order)

  const route = orders.printRoute({ stations: order.stations, key_station: order.key_station })

  sheet.addRow({
    serial: orders.getSerial(order),
    
    start_date: orders.getDate(order),
    start_time: orders.getStartTime(order),

    department: orders.getDepartment(order),
    created_by: orders.getCreatedBy(order),
    direction: orders.getDirection(order),

    origin_city: route?.origin?.city,
    destination_city: route?.destination?.city,
    route: route.routeText,

    waypoints_text: route?.waypointsText?.substring(3),
    passengers: `(${orders.getPassengers(order).count}) ${orders.getPassengers(order).text}`,
    delivery: orders.getDelivery(order),

    base_price: price.price_1 || price.price_2 || 0,
    extra_cities: price.extra_city || 0,
    waypoints: price.extra_stop || 0,
    back_and_forth: price.back_and_forth || 0,
    waitings: price.extra_wait_minute || 0,
    deliveries: price.delivery || 0,
    subtotal: price.subtotal || 0,

    notes: orders.getNotes(order)
  })

  return sheet

}