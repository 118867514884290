import React from 'react'
import { view } from '@risingstack/react-easy-state'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { language, report } from '../stores'
import ExcelButton from './ExcelButton'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

const Wrapper = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <AppBar position="fixed">
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            {
              report.data.issuer.name
            }
            &nbsp;&mdash;&nbsp;
            {
              report.data.issuer.address.street
            }
            &nbsp;
            {
              report.data.issuer.address.house_number
            }
            &nbsp;&mdash;&nbsp;
            {
              report.data.issuer.phone
            }
          </Typography>
          <ExcelButton />
          {
            language.t['locale'] != 'en-US' && (
              <Button color="inherit" onClick={() => language.setCurrent('en-US')}>
                English
              </Button>
            )
          }
          {
            language.t['locale'] != 'he-IL' && (
              <Button color="inherit" onClick={() => language.setCurrent('he-IL')}>
                עברית
              </Button>
            )
          }
        </Toolbar>
      </AppBar>
    </div>
  )
}

export default view(Wrapper)
