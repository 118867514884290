import React from 'react'
import { view } from '@risingstack/react-easy-state'
import { Order } from '..'

const PriceV2 = ({ bid, component }) => {

  return bid ? (
    <div>{bid}</div>
  )
  : component ? (
    <div>
      {
        component?.components?.length > 1 ?  (
          <React.Fragment>
            <Order.PriceComponents component={component} />
          </React.Fragment>
        ) : (
          <React.Fragment>
            { Math.round(component.subtotal / 1.17) }<br></br>
          </React.Fragment>
        )
      }
    </div>
  ) : null
}

export default view(PriceV2)