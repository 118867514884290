import React from 'react'
import { view } from '@risingstack/react-easy-state'

const DriverPrice = ({ order }) => {

  return order.driver_price ? (
    <span>
      { order.driver_price?.subtotal }
      {
        order.driver_price?.missingPrices > 0 && (
          <React.Fragment>
            &nbsp;
            ({order.driver_price.missingPrices})
          </React.Fragment>
        )
      }
    </span>
  ) : <span>*</span>
}

export default view(DriverPrice)