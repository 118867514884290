import React from 'react'
import { view } from '@risingstack/react-easy-state'
import { orders, language } from '../../stores'

const Route = ({ order }) => {

  const { 
    originText,
    destinationText,
    waypointsText
  } = orders.printRoute({ stations: order.stations, key_station: order.key_station })

  return (
    <span>
      ⛳ &nbsp;
      { originText }
      {
        waypointsText && (
          <React.Fragment>
            <br></br>
            <strong>{ waypointsText }</strong>
          </React.Fragment>
        )
      }
      {
        destinationText && (
          <React.Fragment>
            <br></br>
            🏁 &nbsp;{ destinationText }
          </React.Fragment>
        )
      }
    </span>
  )
}

export default view(Route)