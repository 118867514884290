import React from 'react'
import ReactDOM from 'react-dom'
import { Main } from './components'
import { language } from './stores'

language.setCurrent(null, () => {
  ReactDOM.render(<Main.Wrapper />, document.querySelector('#main'))
})

if (module.hot) {
  module.hot.accept()
}