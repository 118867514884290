import React from 'react'
import { view } from '@risingstack/react-easy-state'
import { printHour } from '../../common'

const Hour = ({ order }) => {

  return (
    <span>
      { printHour({ date: order.start_date_timestamp }) }
    </span>
  )
}

export default view(Hour)