import React from 'react'
import { view } from '@risingstack/react-easy-state'
import { Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, makeStyles } from '@material-ui/core'
import { report, language } from '../../stores'

const useStyles = makeStyles({
  table: {
    minWidth: '100%',
  },
})

const RowsTable = ({ creditsOrCharges }) => {

  const classes = useStyles()

  const items = report.data[creditsOrCharges]

  return (
    <Paper>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table" size="small">
          <TableHead>
            <TableRow>
              <TableCell align={language.t['rol']}>
                {language.t['Type']}
              </TableCell>
              <TableCell align={language.t['rol']}>
                {language.t['Amount']}
              </TableCell>
              <TableCell align={language.t['rol']}>
                {language.t['Sum']}
              </TableCell>
              <TableCell align={language.t['rol']}>
                {language.t['VAT']}
              </TableCell>
              <TableCell align={language.t['rol']}>
                {language.t['Total']}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item) => !item.sum ? null : (
              <TableRow key={item._id}>
                <TableCell align={language.t['rol']} component="th" scope="row">
                  { language.t[item.title] || item.title }
                  {
                    item.description && (
                      <span>
                      &nbsp;-&nbsp;
                      <i>
                        { item.description }
                      </i>
                      </span>
                    )
                  }
                  {
                    item.sum > 0 && (
                      <span>
                        {
                          item.required_to_close_the_report.indexOf('invoice') != -1 ? (
                            <span>
                              &nbsp;
                              ({ language.t['Invoice'] })
                            </span>
                          ) : (
                            <span>
                              &nbsp;
                              ({ language.t['Receipt only'] })
                            </span>
                          )
                        }
                      </span>
                    )
                  }
                </TableCell>
                <TableCell align={language.t['rol']} component="th" scope="row">
                  {item.amount}
                </TableCell>
                <TableCell align={language.t['rol']} component="th" scope="row">
                  {Math.round(item.sum_before_vat * 100) / 100}
                </TableCell>
                <TableCell align={language.t['rol']} component="th" scope="row">
                  {Math.round((item.sum-item.sum_before_vat) * 100) / 100}
                </TableCell>
                <TableCell align={language.t['rol']} component="th" scope="row">
                  {Math.round(item.sum * 100) / 100}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

export default view(RowsTable)