import React from 'react'
import { view } from '@risingstack/react-easy-state'
import { printDate } from '../../common'
import { language } from '../../stores'
import Hour from './Hour'

const Date = ({ order }) => {

  return (
    <span>
      { printDate({ date: order.start_date_timestamp, locale: language.t['locale'] }) }
      <br></br>
      <Hour order={order} />
    </span>
  )
}

export default view(Date)