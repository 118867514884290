import Wrapper from './Wrapper'
import Table from './Table'
import Title from './Title'
import Totals from './Totals'

export default {
  Wrapper,
  Table,
  Title,
  Totals
}