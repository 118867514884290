import React from 'react'
import { view } from '@risingstack/react-easy-state'
import { language } from '../../stores'

const Loading = () => {
  return (
    <div>{ language.t['Loading'] }...</div>
  )
}

export default view(Loading)