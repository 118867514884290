import React from 'react'
import { view } from '@risingstack/react-easy-state'
import { report } from '../../stores'

const Contact = ({ order }) => {

  const {
    created_by
  } = order

  return (
    <span>
      { created_by.personal_info.first_name } { created_by.personal_info.last_name }
      <br></br>
      { created_by.personal_info.phone }
    </span>
  )
}

export default view(Contact)