import React from 'react'
import { view } from '@risingstack/react-easy-state'
import { Grid } from '@material-ui/core'
import Title from './Title'
import Table from './Table'
import Totals from './Totals'
import { report } from '../../stores'

const RowsGrid = ({ creditsOrCharges }) => {

  const items = report.data[creditsOrCharges]

  return items.length ? (
    <Grid item xs={12}>
      <Title creditsOrCharges={creditsOrCharges} />
      <Table creditsOrCharges={creditsOrCharges} />
      <Totals creditsOrCharges={creditsOrCharges} />
    </Grid>
  ) : null
}

export default view(RowsGrid)