import React from 'react'
import { view } from '@risingstack/react-easy-state'
import { language } from '../../stores'

const Type = ({ ordersType, ordersCount }) => {

  return (
    <h3>
      {
        language.t[
          ordersType == 'business'
          ? 'Business orders'
          : ordersType == 'credit_cards'
          ? 'Credit card orders'
          : 'Cash orders'
        ]
      }
      &nbsp; ({ordersCount})
    </h3>
  )
}

export default view(Type)