import React from 'react'
import { view } from '@risingstack/react-easy-state'
import { Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, makeStyles } from '@material-ui/core'
import { language, report, orders as ordersStore } from '../../stores'
import { Order } from '../'

const useStyles = makeStyles({
  table: {
    minWidth: '100%',
  },
})

type OrderTypes = 'business' | 'credit_cards' | 'cash'
interface Field {
  name: string,
  component: any
}

const getFields = (ordersType: OrderTypes, order?: any) : Field[] => {
  let base = [
    // {
    //   name: language.t['Status'],
    //   component: () => <Order.Status order={order} />
    // },
    {
      name: language.t['Serial'],
      component: () => <Order.Serial order={order} />
    },
    {
      name: language.t['Date'],
      component: () => <Order.Date order={order} />
    },
    // {
    //   name: language.t['Hour'],
    //   component: () => <Order.Hour order={order} />
    // },
    {
      name: language.t['Created by'],
      component: () => <Order.CreatedBy order={order} />
    },
    {
      name: language.t['Contact'],
      component: () => <Order.Contact order={order} />
    },
    {
      name: language.t['Route'],
      component: () => <Order.Route order={order} />
    },
    {
      name: language.t['Passengers'],
      component: () => <Order.Passengers order={order} />
    },
    {
      name: language.t['Notes'],
      component: () => <span>{ ordersStore.getNotes(order) } </span>
    }
  ]

  if (report.data.type == 'vehicles' || report.data.type == 'drivers') {
    const vehiclesFields = [
      {
        name: language.t['Driver'],
        component: () => <Order.Driver order={order} />
      },
      {
        name: language.t['Vehicle'],
        component: () => <Order.Vehicle order={order} />
      },
      {
        name: language.t['Price'],
        component: () => <Order.PriceV2 bid={order?.bid} component={order?.driver_price} />
      }
    ]
    base = [...base, ...vehiclesFields]
  }

  if (report.data.type == 'organizations') {
    const organizationsFields = [
      {
        name: language.t['Price'],
        component: () => <Order.PriceV2 bid={order?.bid} component={order?.client_price} />
      }
    ]
    base = [...base, ...organizationsFields]
  }

  return base
}

const OrdersDetailsTable = ({ ordersType, orders }) => {

  const classes = useStyles()

  const fields = getFields(ordersType)

  let totalClientPrices = 0, totalDriverPrices = 0, totalBids = 0

  return (
    <Paper>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table" size="small">
          <TableHead>
            <TableRow>
              {
                fields.map(field => {
                  return (
                    <TableCell key={field.name} align={language.t['rol']}>
                      { field.name }
                    </TableCell>
                  )
                })
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {
              orders.map(order => {
                const FieldsTd = getFields(ordersType, order)
                if (order.client_price?.subtotal) totalClientPrices += order.client_price.subtotal
                if (order.driver_price?.subtotal) totalDriverPrices += order.driver_price.subtotal
                if (order.bid) totalBids += parseFloat(order.bid)
                return (
                  <TableRow key={order._id}>
                    {
                      FieldsTd.map(field => {
                        return (
                          <TableCell 
                            key={`field-td-${field.name}`}
                            align={language.t['rol']}
                            component="th"
                            scope="row"
                            style={{fontSize: '65%'}}
                          >
                            <field.component />
                          </TableCell>
                        )
                      })
                    }
                  </TableRow>
                )
              })
            }
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <h3>
          { language.t['Total prices'] }:
          &nbsp;
          {
            report?.data?.type == 'organizations' &&
            totalClientPrices > 0 &&
            <span>
              { Math.round(totalClientPrices / 1.17).toLocaleString() } ₪
              &nbsp; + &nbsp;
              { (totalClientPrices - Math.round(totalClientPrices / 1.17)).toLocaleString() } ₪
              &nbsp; = &nbsp;
              { totalClientPrices.toLocaleString() } ₪
            </span>
          }
          {
            report?.data?.type == 'vehicles' &&
            totalDriverPrices > 0 &&
            <span>
              { Math.round(totalDriverPrices / 1.17).toLocaleString() } ₪
              &nbsp; + &nbsp;
              { (totalDriverPrices - Math.round(totalDriverPrices / 1.17)).toLocaleString() } ₪
              &nbsp; = &nbsp;
              { totalDriverPrices.toLocaleString() } ₪
            </span>
          }
          {
            totalBids > 0 &&
            <span>
              { Math.round(totalBids / 1.17).toLocaleString() } ₪
            </span>
          }
        </h3>
      </div>
    </Paper>
  )
}

export default view(OrdersDetailsTable)