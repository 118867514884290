import { report } from '../'
import { backend } from '../../services'
import { HttpMethods } from '../../ts/enums'

export const setAgreed = async () => {
  try {
    
    const res = await backend.handler({
      method: HttpMethods.post,
      url: `v2/reports/${report.data._id}/agreement`,
      params: {
        agreement_token: report.data.receiver.agreement_token
      }
    })

    report.data.receiver.agreed = true
    delete report.data.receiver.agreement_token
    report.closeAgreeDialog()

  } catch (err) {
    
  }
}