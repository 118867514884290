import React from 'react'
import { view } from '@risingstack/react-easy-state'

const Driver = ({ order }) => {

  return order.driver ? (
    <span>
      { order.driver.personal_info.first_name } { order.driver.personal_info.last_name }
      <br></br>
      ({ order.driver.personal_info.nickname })
    </span>
  ) : (
    <span>
      *
    </span>
  )
}

export default view(Driver)