import React, { useEffect } from 'react'
import { view } from '@risingstack/react-easy-state'
import { Grid } from '@material-ui/core'
import { report } from '../../stores'
import { Title, Rows, Loading, OrdersDetails, Header } from '../'
import SetAgreed from '../SetAgreed'
import Invoices from '../Invoices'

const Wrapper = () => {

  useEffect(() => {
    (async () => {
      try {
        const res = await report.fetch()
        report.data = res.report
        report.inited = true
      } catch (err) {
        alert(`${err.message} ${err.name}`)
      }
    })()
  }, [])

  return (report.inited && report.data) ? (
    <Grid container spacing={3}>
      <Header />
      <Title.Wrapper />
      <Rows.Wrapper creditsOrCharges='charges' />
      <Rows.Wrapper creditsOrCharges='credits' />
      <SetAgreed />
      <Invoices />
      <OrdersDetails.Wrapper />
      <SetAgreed />
    </Grid>
  ) : !report.inited ? (
    <Loading.Wrapper />
  ) : (
    <div>error please try again later</div>
  )
}

export default view(Wrapper)