import React from 'react'
import { view } from '@risingstack/react-easy-state'

const Vehicle = ({ order }) => {

  const displayer = (lpn: string) => {
    if (lpn.length == 7) {
      lpn = `${lpn.slice(0, 2)}-${lpn.slice(2)}`
      lpn = `${lpn.slice(0, 6)}-${lpn.slice(6)}`
      return lpn
    }
    if (lpn.length == 8) {
      lpn = `${lpn.slice(0, 3)}-${lpn.slice(3)}`
      lpn = `${lpn.slice(0, 7)}-${lpn.slice(7)}`
    }
    return lpn
  }

  return (
    <span>
      { order.vehicle.manufacturer } { order.vehicle.model }
      <br></br>
      { displayer(order.vehicle.license_plate_number) }
    </span>
  )
}

export default view(Vehicle)