import React from 'react'
import { view as State } from '@risingstack/react-easy-state'
import { language } from '../stores'

interface Params {
  start_of_month: Date;
}

const PrintPeriod = ({ start_of_month }: Params) => {
  try {

    const period = new Date(start_of_month).toLocaleString(language.t['locale'], {
      month: 'long',
      year: 'numeric'
    })

    return (
      <h2>
        { period }
      </h2>
    )
    
  } catch (err) {
    return null
  }
}

export default State(PrintPeriod)
