import React from 'react'
import { view as State } from '@risingstack/react-easy-state'
import { language, report } from '../stores'
import { Button, Grid, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'

const SetAgreed = () => {

  if (!report.data) return null
  
  try {
    if (report.data.receiver.agreed) {
      return (
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          ✅
          &nbsp;
          { language.t['Confirmed'] }
        </Grid>
      )
    }
  } catch (err) {}

  try {
    if (!report.data.receiver.agreement_token) return null
  } catch (err) {}

  return (
    <Grid item xs={12} style={{ textAlign: 'center' }}>
      <Button color='primary' variant='contained' onClick={report.openAgreeDialog}>
        { language.t['Confirm this report'] }
      </Button>
      <Dialog
        open={report.agree_dialog_opened}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          { language.t['Confirm this report'] }?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            { language.t['Before report confirmation it is wise to browse the correctness of it'] }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={report.closeAgreeDialog}>
            { language.t['Cancel'] }
          </Button>
          <Button onClick={report.setAgreed} color='primary' autoFocus>
            { language.t['Confirm'] }
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  )
}

export default State(SetAgreed)
