import exceljs from 'exceljs/dist/es5/exceljs.browser'

export const createWorkbook = () => {

  // create workbook
  const workbook = new exceljs.Workbook()

  // set workbook properties
  workbook.created = new Date()

  return workbook

}