import React from 'react'
import { view } from '@risingstack/react-easy-state'
import { Grid, Paper, makeStyles } from '@material-ui/core'
import { report } from '../../stores'
import { Period } from '../'

const useStyles = makeStyles({
  paper: {
    padding: '2%',
    textAlign: 'center',
    color: 'black',
  },
  recipient: {
    textDecoration: 'underline',
  },
})

const Wrapper = () => {
  const classes = useStyles()

  return (
    <Grid item xs={12}>
      <Paper className={classes.paper}>
        <h1 style={{ marginTop: '80px' }}>
          {report.data.receiver.name}
          {report.data.receiver.phone && (
            <React.Fragment>
              &nbsp;&mdash;&nbsp;
              {report.data.receiver.phone}
            </React.Fragment>
          )}
        </h1>

        <Period
          start_of_month={report.data.start_of_month}
        />
      </Paper>
    </Grid>
  )
}

export default view(Wrapper)
