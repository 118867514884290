import { language } from '..'

export const getPassengers = (order): { count: number; text: string } => {
  let count = 0
  let text = ``

  for (const station of order.stations) {
    count = count + parseInt(station.passengers_amount)
    if (station.contact) {
      text = `${text}${station.contact.first_name} ${station.contact.last_name} | `
    }
  }

  text = text.substr(0, text.length - 3)

  return { count, text }
}