import { store } from '@risingstack/react-easy-state'
import { getContact } from './get-contact'
import { getCreatedBy } from './get-created-by'
import { getDate } from './get-date'
import { getDelivery } from './get-delivery'
import { getDepartment } from './get-department'
import { getDirection } from './get-direction'
import { getNotes } from './get-notes'
import { getPassengers } from './get-passengers'
import { getPrice } from './get-price'
import { getSerial } from './get-serial'
import { getStartTime } from './get-start-time'
import printRoute from './print-route'

const orders = store({
  printRoute,
  getDate,
  getCreatedBy,
  getContact,
  getPassengers,
  getDelivery,
  getDirection,
  getPrice,
  getSerial,
  getNotes,
  getDepartment,
  getStartTime
})

export default orders