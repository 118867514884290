import React from 'react'
import { view } from '@risingstack/react-easy-state'
import { orders } from '../../stores'

const Passengers = ({ order }) => {

  return order.stations.map((station, index) => {
    const { contact } = station
    return (
      <p key={`contact-${index}`}>
        { contact.first_name } { contact.last_name }
      </p>
    )
  })
}

export default view(Passengers)