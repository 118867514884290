import React from 'react'
import { view } from '@risingstack/react-easy-state'
import { language } from '../../stores'

const RowsTitle = ({ creditsOrCharges }) => {

  const name = creditsOrCharges == 'credits'
  ? 'Credits overview'
  : 'Charges overview'

  return (
    <h2 style={{textAlign: 'center'}}>
      { language.t[name] }
    </h2>
  )
}

export default view(RowsTitle)