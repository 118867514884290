import { store } from '@risingstack/react-easy-state'

const config = store({
  backend: {
    baseURL: 'http://localhost:3002/'
  }
})

if (process.env.NODE_ENV == 'production') {
  config.backend.baseURL = 'https://www.api.taxi4you.co.il/'
}

export default config