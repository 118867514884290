import React, { useState } from 'react'
import { view } from '@risingstack/react-easy-state'
import { orders, language } from '../../stores'
import { Order } from '../'
import { Link } from '@material-ui/core'

const Price = ({ bid, component }) => {

  const [showExtras, setShowExtras] = useState(false)
  return bid ? (
    <div>{bid}</div>
  )
  : component ? (
    <div>
      {
        !showExtras && (
          <React.Fragment>
            { Math.round(component.subtotal / 1.17) }<br></br>
          </React.Fragment>
        )
      }
      {
        component?.components?.length > 1 &&
        !showExtras && (
          <Link onClick={() => setShowExtras(true) } style={{cursor: 'pointer'}}>
            { language.t['Show extras'] }
          </Link>
        )
      }
      {
        showExtras && (
          <React.Fragment>
            <Order.PriceComponents component={component} />
            <Link onClick={() => setShowExtras(false)} style={{cursor: 'pointer'}}>
              { language.t['Hide extras'] }
            </Link>
          </React.Fragment>
        )
      }
    </div>
  ) : null
}

export default view(Price)