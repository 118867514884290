import React from 'react'
import { view } from '@risingstack/react-easy-state'
import { Grid } from '@material-ui/core'
import Table from './Table'
import Title from './Title'

const Type = ({ ordersType, orders }) => {

  return (
    <Grid item xs={12}>
      <Title ordersType={ordersType} ordersCount={orders.length} />
      <Grid item xs={12} style={{marginBottom: '5%'}}>
        <Table ordersType={ordersType} orders={orders} />
      </Grid>
    </Grid>
  )
}

export default view(Type)