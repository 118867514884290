import { getHeaders } from './get-headers'
import { http } from '../../common'
import { config } from '../../stores'
import { HttpRequestParams } from '../../ts/interfaces'

export type HandlerResult = Promise<any>

export default ({
  method,
  url,
  params,
  data = {},
  headers = {}
} : HttpRequestParams) : HandlerResult => {
  return new Promise(async (resolve, reject) => {
    try {

      // headers
      headers = {
        ...getHeaders(),
        ...headers
      }

      const res = await http.request({
        method,
        url: `${config.backend.baseURL}${url}`,
        ...(params) ? { params } : {},
        data,
        headers
      })

      return resolve(res)

    } catch(err) {
      return reject(err)
    }
  })
}