import { Station, KeyStation, Address } from '../../ts/interfaces'

export interface PrintRouteParams {
  stations: Station[],
  key_station?: KeyStation
}

export interface PrintRouteResult {
  origin: Address,
  originText: string,
  waypoints?: Address[],
  waypointsText?: string,
  destination?: Address,
  destinationText?: string,
  routeText: string
}

const getText = ({
  street,
  city,
  house_number
}: Address) : string => {
  let str = `${street}`
  if (house_number) {
    str = `${str} ${house_number}, `
  }
  str = `${str} ${city}`
  return str
}

export default ({
  stations,
  key_station
}: PrintRouteParams) : PrintRouteResult => {

  // define origin and destination
  let origin: Address,
  destination: Address | undefined,
  originText: string,
  destinationText: string | undefined,
  waypoints: Address[] | undefined,
  waypointsText: string | undefined,
  routeText: string

  if (key_station) {
    origin = !key_station.is_origin
    ? stations[0].address
    : key_station.address
    destination = !key_station.is_origin
    ? key_station.address
    : stations[stations.length - 1].address
  } else {
    origin = stations[0].address
  }

  originText = getText(origin)
  if (destination) {
    destinationText = getText(destination)
  }

  // get waypoints
  if (stations.length > 1) {
    const waypointsAsStations: Station[] = JSON.parse(JSON.stringify(stations))
    waypoints = []
    if (!key_station.is_origin) {
      waypointsAsStations.shift()
    } else {
      waypointsAsStations.pop()
    }
    waypointsText = ''
    waypointsAsStations.map(wpas => {
      waypointsText = `${waypointsText} + ${getText(wpas.address)}`
      waypoints.push(wpas.address)
    })
  }

  // routeText
  routeText = originText

  if (destinationText) {
    routeText = `${routeText} - ${destinationText}`
  }

  return {
    origin,
    originText,
    waypoints,
    waypointsText,
    destination,
    destinationText,
    routeText
  }

}