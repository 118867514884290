import { backend } from '../../services'
import * as queryString from 'query-string'
import { HttpMethods } from '../../ts/enums'

export type FetchResult = Promise<any>

export default () : FetchResult => {
  return new Promise(async (resolve, reject) => {
    try {

      const {
        _report,
        read_token
      } = queryString.parse(location.search)

      const res = await backend.handler({
        method: HttpMethods.get,
        url: 'v2/reports/with-token',
        params: {
          _report,
          read_token
        }
      })
      
      return resolve(res)

    } catch(err) {
      return reject(err)
    }
  })
}