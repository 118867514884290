import exceljs from 'exceljs/dist/es5/exceljs.browser'
import { language } from '../../..'

export const createOrdersSheetCols = (sheet: exceljs.Worksheet): exceljs.Worksheet => {

  sheet.columns = [
    { header: language.t['Serial'], key: 'serial', width: 10 },
    { header: language.t['Date'], key: 'start_date', width: 13 },
    { header: language.t['Hour'], key: 'start_time', width: 8 },
    { header: language.t['Department'], key: 'department', width: 15 },
    { header: language.t['Created by'], key: 'created_by', width: 25 },
    { header: language.t['Direction'], key: 'direction', width: 8 },

    { header: language.t['Origin'], key: 'origin_city', width: 15 },
    { header: language.t['Destination'], key: 'destination_city', width: 15 },

    { header: language.t['Route'], key: 'route', width: 70 },
    { header: language.t['Waypoints'], key: 'waypoints_text', width: 40 },
    { header: language.t['Passengers'], key: 'passengers', width: 40 },

    { header: language.t['Base price'], key: 'base_price', width: 12 },
    { header: language.t['Extra cities'], key: 'extra_cities', width: 12 },
    { header: language.t['Waypoints'], key: 'waypoints', width: 12 },
    { header: language.t['Back and forth'], key: 'back_and_forth', width: 12 },
    { header: language.t['Waitings'], key: 'waitings', width: 12 },
    { header: language.t['Deliveries'], key: 'deliveries', width: 12 },
    { header: language.t['Subtotal'], key: 'subtotal', width: 12 },

    { header: language.t['Notes'], key: 'notes', width: 70 },
    { header: language.t['Delivery'], key: 'delivery', width: 70 },
  ]

  return sheet

}