import React from 'react'
import { view } from '@risingstack/react-easy-state'
import { orders } from '../../stores'

const Notes = ({ order }) => {

  return (
    <span>
      {/* { orders.getNotes(order) } */}
      hello
    </span>
  )
}

export default view(Notes)