import React from 'react'
import { view } from '@risingstack/react-easy-state'
import { report } from '../../stores'

const Contact = ({ order }) => {

  const {
    organization,
    contact
  } = order

  if (organization && report.data.type != 'organizations') {
    return (
      <span>
        { organization.title } | { contact.first_name } { contact.last_name }
        <br></br>
        { contact.phone }
      </span>
    )
  }

  return (
    <span>
      { contact.first_name } { contact.last_name }
      <br></br>
      { contact.phone }
    </span>
  )
}

export default view(Contact)