import Hour from './Hour'
import Date from './Date'
import Status from './Status'
import Contact from './Contact'
import Route from './Route'
import Driver from './Driver'
import Vehicle from './Vehicle'
import DriverPrice from './DriverPrice'
import ClientPrice from './ClientPrice'
import Passengers from './Passengers'
import CreatedBy from './CreatedBy'
import Price from './Price'
import PriceV2 from './Price.V2'
import PriceComponents from './PriceComponents'
import Serial from './Serial'
import Notes from './Notes'

export default {
  Hour,
  Date,
  Status,
  Contact,
  Route,
  Driver,
  Vehicle,
  DriverPrice,
  ClientPrice,
  Passengers,
  CreatedBy,
  Price,
  PriceV2,
  PriceComponents,
  Serial,
  Notes
}