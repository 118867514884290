import React from 'react'
import { view } from '@risingstack/react-easy-state'
import { orders } from '../../stores'

const Serial = ({ order }) => {

  return (
    <span>
      { orders.getSerial(order) }
    </span>
  )
}

export default view(Serial)