import React from 'react'
import { view } from '@risingstack/react-easy-state'
import { language } from '../../stores'

const PriceComponents = ({ component }) => {

  return (
    <div>
      {
        component.components.map(item => {
          return (
            <p key={item._id}>
              <strong>
                { language.t[item.component] || item.component }:
              </strong>
              <br></br>
              { language.t['Unit price'] }: { item.status == 'OK' ? Math.round(item.unit_price / 1.17) : language.t['N/A'] };&nbsp;
              { language.t['Units'] }: { item.units || 0 }&nbsp;
              = { language.t['Total'] }: { Math.round(item.total / 1.17) }
            </p>
          )
        })
      }
      <p>
        <strong>{ language.t['Total'] }:</strong>
        <br></br>
        { Math.round( component.subtotal / 1.17 ) }
      </p>
    </div>
  )
}

export default view(PriceComponents)