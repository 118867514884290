import React from 'react'
import { view as State } from '@risingstack/react-easy-state'
import { language, report } from '../stores'
import { Grid, Link } from '@material-ui/core'

const Invoices = () => {
  try {

    const chargesInvoice = report?.data?.charges_invoice ? (
      <div>
        <a
          href={report.data.charges_invoice.pdf_link}
          target="_blank"
          style={{cursor: 'pointer', fontWeight: 'bold'}}
        >
          📥 {language.t['Download charges invoice']}
        </a>
      </div>
    ) : null

    const creditsInvoice = report?.data?.credits_invoice ? (
      <div>
        <a
          href={report.data.credits_invoice.pdf_link}
          target="_blank"
          style={{cursor: 'pointer', fontWeight: 'bold'}}
        >
          📥 {language.t['Download credits invoice']}
        </a>
      </div>
    ) : null

    const receipt = report?.data?.receipt ? (
      <div>
        <a
          href={report.data.receipt.pdf_link}
          target="_blank"
          style={{cursor: 'pointer', fontWeight: 'bold'}}
        >
          📥 {language.t['Download receipt']}
        </a>
      </div>
    ) : null

    if (!chargesInvoice && !creditsInvoice && !receipt) {
      return null
    }

    return (
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        { chargesInvoice }
        { creditsInvoice }
        { receipt }
      </Grid>
    )
    
  } catch (err) {
    return null
  }
}

export default State(Invoices)
