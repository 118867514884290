import { language } from '..'

const parse = (price: any) => Math.round(parseInt(price) / 1.17 * 100) / 100

interface Price {
  price_1?: number;
  price_2?: number;
  extra_city?: number;
  extra_stop?: number;
  delivery?: number;
  extra_wait_minute?: number;
  back_and_forth?: number;
  subtotal?: number;
}

export const getPrice = (order): Price => {

  // define
  const price: Price = {}

  // order bid
  if (order.bid) {
    return {
      subtotal: parse(order.bid)
    }
  }

  // price object
  let pco = order?.client_price || order?.driver_price
  if (!pco) {
    return {}
  }

  // add subtotal
  price.subtotal = parse(pco.subtotal)
  
  // pcs
  let pcs = pco.components || []

  // price components
  for (const pc of pcs) {
    price[pc.key] = parse(pc.total)
  }

  return price

}