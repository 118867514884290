import exceljs from 'exceljs/dist/es5/exceljs.browser'
import { addOrderRow } from './row'
import { createOrdersSheet } from './sheet'
import { createOrdersSheetCols } from './cols'
import { report } from '../../..'

export const addOrdersSheet = (workbook: exceljs.Workbook): exceljs.Workbook => {

  // get orders
  const orders = report.getAllOrders()
  if (!orders.length) return workbook

  // create the sheet
  let ordersSheet = createOrdersSheet(workbook)
    
  // create the sheet columns
  ordersSheet = createOrdersSheetCols(ordersSheet)

  // add each order as a row in the sheet
  for (const order of orders) {
    ordersSheet = addOrderRow(ordersSheet, order)
  }

  return workbook

}