import React from 'react'
import { view } from '@risingstack/react-easy-state'
import { language } from '../../stores'

const Status = ({ order }) => {

  return (
    <span>
      { language.t[order.status] }
    </span>
  )
}

export default view(Status)