import React from 'react'
import { view } from '@risingstack/react-easy-state'
import { language, report } from '../../stores'

const RowsTotals = ({ creditsOrCharges }) => {

  const {
    charges_invoice_count,
    charges_invoice_count_before_vat,
    charges_total_count,
    charges_total_count_before_vat,
    credits_invoice_count,
    credits_invoice_count_before_vat,
    credits_total_count,
    credits_total_count_before_vat,
    total_sum
  } = report.data

  return creditsOrCharges == 'charges' ? (
    <div style={{textAlign: 'center'}}>
      <h4>
        {
          charges_invoice_count > 0 && (
            <React.Fragment>
              { language.t['Invoice'] }:
              &nbsp;
              <strong>
                { charges_invoice_count_before_vat }
                &nbsp;
                +
                &nbsp;
                { Math.round( charges_invoice_count_before_vat * 17) / 100 } { language.t['VAT'] } 
              </strong>
              &nbsp;
              =
              &nbsp;
            </React.Fragment>
          )
        }
        <strong>
          { charges_invoice_count }
        </strong>
      </h4>
      {
        total_sum > 0 && (
          <h4>
            <strong>
            { language.t['Receipt'] }:
            </strong>
            &nbsp;
            { total_sum.toFixed(0) }
          </h4>
        )
      }
    </div>
  ) : (
    <div style={{textAlign: 'center'}}>
      <h4>
        {
          credits_invoice_count > 0 && (
            <React.Fragment>
              { language.t['Invoice'] }:
              &nbsp;
              <strong>
                { credits_invoice_count_before_vat }
                &nbsp;
                +
                &nbsp;
                { Math.round( credits_invoice_count_before_vat * 17) / 100 } { language.t['VAT'] }
              </strong>
              &nbsp;
              =
              &nbsp;
            </React.Fragment>
          )
        }
        <strong>
          { credits_invoice_count }
        </strong>
      </h4>
      {
        total_sum < 0 && (
          <h4>
            <strong>
            { language.t['Receipt'] }:
            </strong>
            &nbsp;
            { total_sum.toFixed(0) }
          </h4>
        )
      }
    </div>
  )
}

export default view(RowsTotals)