import { report } from '..'

export const getAllOrders = (): any[] => {
  try {

    let orders = []

    Object.keys(report?.data?.ordersTypes).map(key => {
      if (report.data.ordersTypes[key]?.length) {
        orders = [...orders, ...report.data.ordersTypes[key]]
      }
    })

    return orders

  } catch (err) {
    return []
  }
}