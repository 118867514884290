import React from 'react'
import { view } from '@risingstack/react-easy-state'
import { Grid } from '@material-ui/core'
import { report } from '../../stores'
import Type from './Type'

const Wrapper = () => {

  const { ordersTypes } = report.data

  if (!ordersTypes) return null

  return (
    <Grid item xs={12}>
      {
        ordersTypes.business && (
          <Type ordersType='business' orders={ordersTypes.business} />
        )
      }
      {
        ordersTypes.credit_cards && (
          <Type ordersType='credit_cards' orders={ordersTypes.credit_cards} />
        )
      }
      {
        ordersTypes.cash && (
          <Type ordersType='cash' orders={ordersTypes.cash} />
        )
      }
    </Grid>
  )
}

export default view(Wrapper)